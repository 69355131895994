import React, {useState, useReducer} from 'react';
import {
  readPermissions, 
  writePermissions, 
  readInvoicePermissions, 
  writeInvoicePermissions
} from '../../permissions';
import './style.css';

function Bakery() {
  const ovenReducer = (state, data) => {
    return state.map(permission => {
      if (Array.isArray(data)) {
        data.forEach(obj => {
          if (permission.entity === obj.entity) {
            permission = {...permission, selected: obj.selected};
          }
        });
        return permission;
      } else if (permission.entity === data.entity) {
        return { ...permission, selected: data.selected};
      } else {
        return permission;
      }
    });
  }

  const [readOven, setReadOven] = useReducer(ovenReducer, readPermissions);
  const [writeOven, setWriteOven] = useReducer(ovenReducer, writePermissions);
  const [lncliCommand, setLncliCommand] = useState('');
  const [fileName, setFileName] = useState('');
  const [timeout, setTimeout] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  let textarea = React.createRef();
  let scrollTo = React.createRef();

  const handleGenerate = () => {
    let lncli = '';

    readOven.forEach(permission => {
      if (permission.selected === true) {
        lncli += ` ${permission.entity}:${permission.action}`;
      }
    });

    writeOven.forEach(permission => {
      if (permission.selected === true) {
        lncli += ` ${permission.entity}:${permission.action}`;
      }
    });

    if (lncli) {
      lncli = `lncli bakemacaroon${lncli}`;

      if (fileName) {
        lncli += ` --save_to=${fileName}`;
      }
      if (timeout) {
        lncli += ` --timeout=${timeout}`;
      }
      if (ipAddress) {
        lncli += ` --ip_address=${ipAddress}`;
      }

      setLncliCommand(lncli);
      scrollTo.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const setInvoicePermissions = (e) => {
    e.preventDefault();

    setReadOven(readInvoicePermissions);
    setWriteOven(writeInvoicePermissions);
  }

  const handleFocus = (e) => {
    const input = textarea.current;
    input.setSelectionRange(0, input.value.length)
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>LND Macaroon Bakery</h1>
      </header>
      <div className="App-body">
        <p>
          Select permissions to bake a macaroon. Copy the generated command and run it on your server. 
        </p>
        <br />

        <div className="oven">
          <h2>Read permissions</h2>
        {
          readOven.map((permission, index) => (
            <span
              key={index} 
              className={`label ${permission.selected ? 'active' : ''}`}
              onClick={() => setReadOven({
                entity: permission.entity,
                selected: !permission.selected
              })}
            >{permission.entity}</span>
          ))
        }
        </div>

        <div className="oven">
          <h2>Write permissions</h2>
        {
          writeOven.map((permission, index) => (
            <span
              key={index} 
              className={`label ${permission.selected ? 'active' : ''}`}
              onClick={() => setWriteOven({
                entity: permission.entity,
                selected: !permission.selected
              })}
            >{permission.entity}</span>
          ))
        }
        </div>

        <p>
          <a href="#" onClick={(e) => setInvoicePermissions(e)}>Set invoice permissions</a>
        </p>

        <p>
          <input type="text" onChange={(e) => setFileName(e.currentTarget.value)} placeholder="Filename to save the macaroon to (e.g. custom.macaroon)" value={fileName} />
          <input 
            className="input-timeout"
            type="text" 
            onChange={(e) => setTimeout(e.currentTarget.value)} 
            value={timeout} 
            placeholder="Macaroon validity timeout (seconds)" />
          <input 
            className="input-ip"
            type="text" 
            onChange={(e) => setIpAddress(e.currentTarget.value)} 
            value={ipAddress} 
            placeholder="Lock to a specific IP"
          />
        </p>
        <p>
          <button onClick={() => handleGenerate()}>Generate</button>
        </p>

        <p>
          <textarea 
            type="text" 
            ref={textarea}
            readOnly
            placeholder="Result" 
            value={lncliCommand} 
            onClick={handleFocus}
          />
        </p>

        {/* <br/><br/>
        <div>
          <h3>Help</h3>
        </div> */}

      </div>
      <div ref={scrollTo}></div>
    </div>
  );
}

export default Bakery;
