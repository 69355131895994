const readPermissions = [{
  entity: "onchain",
  action: "read",
  selected: false,
}, {
  entity: "offchain",
  action: "read",
  selected: false,
}, {
  entity: "address",
  action: "read",
  selected: false,
}, {
  entity: "message",
  action: "read",
  selected: false,
}, {
  entity: "peers",
  action: "read",
  selected: false,
}, {
  entity: "info",
  action: "read",
  selected: false,
}, {
  entity: "invoices",
  action: "read",
  selected: false,
}];

const writePermissions = [{
  entity: "onchain",
  action: "write",
  selected: false,
}, {
  entity: "offchain",
  action: "write",
  selected: false,
}, {
  entity: "address",
  action: "write",
  selected: false,
}, {
  entity: "message",
  action: "write",
  selected: false,
}, {
  entity: "peers",
  action: "write",
  selected: false,
}, {
  entity: "info",
  action: "write",
  selected: false,
}, {
  entity: "invoices",
  action: "write",
  selected: false,
}, {
  entity: "signer",
  action: "generate",
  selected: false,
}, {
  entity: "macaroon",
  action: "generate",
  selected: false,
}];

const readInvoicePermissions = [{
  entity: 'invoices',
  selected: true
}, {
  entity: 'address',
  selected: true
}, {
  entity: 'onchain',
  selected: true
}];

const writeInvoicePermissions = [{
  entity: 'invoices',
  selected: true
}, {
  entity: 'address',
  selected: true
}];

export {
  readPermissions, 
  writePermissions, 
  readInvoicePermissions, 
  writeInvoicePermissions
};